<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Редакция</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <img src="/images/icon/12.png" alt="12+" class="image_age">
                <b-row v-for="(item, index) in data" :key="index">
                    <b-col cols="5" class="edition_name" v-html="item.name"></b-col>
                    <b-col cols="7" class="edition_description" v-html="item.description"></b-col>
                </b-row>
                <div class="bord mb-2"></div>
                <b-row>
                    <b-col cols="12" class="edition_description">Сайт okaygorod.com (далее по тексту — Сайт) является средством массовой информации, зарегистрированным в установленном законодательством Российской Федерации порядке (св-во о регистрации СМИ ЭЛ № ФС 77 - 75366 от 01.04.2019 года, выдано Федеральной службой по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор).</b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Edition",
    components: {},
    metaInfo() {
        return {
            title: `Редакция `,
            meta: [
                {name: 'description', itemprop: 'description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}` },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Реклама  - ${this.$myCity.name}`},
                {property: 'og:description', content: `Редакция информационного портала Окей Город ${this.$myCity.name}`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            data: [{
                    name: 'Название',
                    description: 'Окей Город'
                },
                {
                    name: 'Учредитель',
                    description: 'Маргарян К. В.'
                },
                {
                    name: 'Главный редактор:',
                    description: 'Маргарян К. В.'
                },
                {
                    name: 'Периодичность:',
                    description: 'Ежедневно, круглосуточно, бесплатно'
                },
                {
                    name: 'Адрес редакции:',
                    description: '140104, Московская обл., Раменский р-н, г. Раменское, ул. Красная, д. 18, кв. 24'
                },
                {
                    name: 'Телефон:',
                    description: '+7(915) 090-49-99'
                },
                {
                    name: 'Электронная почта:',
                    description: 'ooogoroda@gmail.com'
                },
                {
                    name: 'Номер свидетельства:',
                    description: 'ЭЛ № ФС 77 - 75366'
                },
                {
                    name: 'Возрастные ограничения:',
                    description: '12+'
                },
            ]
        }
    },

    methods: {

    },
    mounted() {
        this.$scrollToTop();
    }

}
</script>
<style scoped>
.edition_name {
    font-size: 14px;
    text-align: left;
    color: #959595;
    clear: both;
}

.edition_description {
    text-align: left;
    color: #151515;
    font-size: 14px;
}

.row {
    margin-bottom: 10px;
}

.image_age {
    width: 40px;
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>